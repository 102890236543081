import Header from './components/Header';
import Info from './components/Info';
import Features from './components/Features';
import SpecificationsTable from './components/Specifications';
import Footer from './components/Footer';

function App() {
  return (
    <div>
        <Header />
      <div className='info-wrapper'>
        <Info/>
        <Features />
        <SpecificationsTable />
      </div>
      <Footer />
    </div>
  );
}

export default App;
