import React from "react";

export default function Footer() {
  return (
    <div className="footerContainer">
      <div>
        <img src="./assets/material-symbols_call-outline.svg" alt="" />
        <span style={{display: "flex", gap: '0.5rem'}}>
          <span> +234 814 298 1261 </span>
          <span style={{color: 'rgba(33, 62, 122, 1)', fontWeight: '600'}}> | </span>
          <span> 076 284 5733 </span>
        </span>
      </div>

      <div>
        <img src="./assets/material-symbols_mail-outline.svg" alt="" />
        <span>
          <span> info@figorr.com </span>
        </span>
      </div>

      <div>
        <img src="./assets/mdi_web.svg" alt="" />
        <span>www.figorr.com </span>
      </div>
    </div>
  );
}
