import React from "react";

const SpecificationsTable = () => {
  return (
    <div className="specifications_container">
        <p> Specifications </p>
    <div className="spec-table">
        <div className="spec-row header-row">
        <div className="spec-item gray">Items</div>
        <div className="spec-detail gray">Specifications</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Model No</div>
        <div className="spec-detail">TT18-4G-S</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Transmission interval</div>
        <div className="spec-detail gray">Programmable to default user setting</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Usage Type</div>
        <div className="spec-detail">Single Use</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Weight</div>
        <div className="spec-detail gray">138g</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Inner Lithium Battery</div>
        <div className="spec-detail">Built-in 3.7V/3000mAh Lithium Battery</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Transmission Mode</div>
        <div className="spec-detail gray">4G/2G</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Other Sensor</div>
        <div className="spec-detail">Light Sensor</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Accuracy of Temperature and Humidity Sensor</div>
        <div className="spec-detail gray">Temperature (± 0.3ºC) Humidity (± 3%RH)</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Dimension</div>
        <div className="spec-detail">90mm*64mm*27mm (3.54in*2.52in*1.06in)</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Working Temperature Condition</div>
        <div className="spec-detail gray">Up to 90% (Non-condensing)</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Location Type</div>
        <div className="spec-detail">LBS Base Station Positioning</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Resolution</div>
        <div className="spec-detail gray">0.1ºC</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">IP Rating</div>
        <div className="spec-detail">IP54</div>
      </div>
      <div className="spec-row">
        <div className="spec-item gray">Battery</div>
        <div className="spec-detail gray">Built-in 3.7V/3000mAh lithium battery</div>
      </div>
      <div className="spec-row">
        <div className="spec-item">Certifications</div>
        <div className="spec-detail">ISO9001, CE</div>
      </div>
    </div>
    </div>
  );
};

export default SpecificationsTable;
