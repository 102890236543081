import React from 'react'

export default function Features() {
  return (
    <div className='features-wrapper'>
            <div className='listed-features-container'> 
            <p> Features </p>
            <div className='listed-features'>  
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> High precise temperature and humidity sensor </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> Multi-point LBS position </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> Temperature, humidity, light and location tracking </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> Real time monitoring with cloud-based platform </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> External API capabilities </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> Automatically generates PDF report for emergency </span>
                </div>
                <div> 
                <img src='./assets/Vector (1).svg' alt='' />
                <span> Programmable transmission period </span>
                </div>
            </div>
            </div>

            <img className='features-img' src='./assets/Group 1263.svg' alt='' />
    </div>
  )
}
