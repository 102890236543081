import React from 'react'

export default function Info() {
  return (
    <div className='info-container'>
        <p className='info-head'>  GSM Real-Time Temp&RH <br/> and Location Data Logger</p>
        <p className='info-txt'> 24/7 Cold Chain Management </p>
    </div>
  )
}
