import React from 'react'

export default function Header() {
  return (
    <div className='header-container'>
        <img src='./assets/Group.svg' alt='logo'/>
        <div className='header-nav'>
            <span>Nigeria</span>
            <span style={{color: 'rgba(33, 62, 122, 1)'}}> | </span>
            <span>Kenya</span>
            <span style={{color: 'rgba(33, 62, 122, 1)'}}> | </span>
            <span>South Africa</span>
        </div>
    </div>
  )
}
